<template>
  <div class="content detail-box" :class="[conf.detailClass || 'md']" id="entity_detail">
    <form-area
        ref="detailForm"
        class="flex-box vertical add-form"
        layout="horizontal"
        :form-item-row-type="conf.formItemRowType"
        :label-col="conf.labelCol || {span: 4}"
        :wrapper-col="conf.wrapperCol || {span: 20}"
        :items="conf.items"
        :entity="form"
        @cancel="cancel()"
        @confirm="confirm" v-show="isAdd || edit"></form-area>
    <div class="detail-ctx" v-if="detail" v-show="!isAdd && !edit">
      <div class="detail-ctx-ti">{{`${conf.name}详情`}}</div>
      <div class="detail-ctx-box">
        <div class="other-title">基本信息</div>
        <ul class="detail-info">
          <li v-for="val in renderItems" :key="val.key">
            <div class="detail-info-item" :class="[val.className]">
              <div class="detail-info-label">{{val.label}}</div>
              <div class="detail-info-value" v-if="val.img">
                <div class="file-list" v-if="detail[val.key]">
                  <img class="file-item" :src="f" v-for="(f, i) in detail[val.key].split(',')" :key="i" @click="previewImg(f, val)">
                </div>
                <div v-else>无</div>
              </div>
              <div class="detail-info-value" v-else-if="val.file">
                <div class="files-list" v-if="detail[val.key]">
                  <div
                    class="files-item"
                    v-for="(f, i) in Array.isArray(detail[val.key]) ? detail[val.key] : detail[val.key].split(',')"
                    :key="i">
                    <div class="files-item-name">{{ typeof f === "object" ? f.name : getUrlFileName(f) }}</div>
                    <a-icon type="download" class="download-icon" @click="downloadFile(f)" />
                  </div>
                </div>
                <div v-else>无</div>
              </div>
              <div class="detail-info-value" v-else-if="val.media">
                <div class="media-list" v-if="detail[val.key]">
                  <div class="media-item" v-for="(m, i) in detail[val.key]" :key="i">
                    <div class="media-preview">
                      <video controls :src="m.url" :poster="m.poster" v-if="m.type == 'video'"></video>
                      <audio controls :src="m.url" v-else-if="m.type == 'audio'"></audio>
                      <img :src="m.url" @click="previewMedia(m, val)" v-else/>
                    </div>
                  </div>
                </div>
                <span v-else>无</span>
              </div>
              <div class="detail-info-value" v-else-if="val.textarea">
                <article v-if="detail[val.key]">
                  <p class="detail-info-p" v-for="(p, i) in detail[val.key].split(/\r|\n/)" :key="i">{{p}}</p>
                </article>
                <span v-else>无</span>
              </div>
              <div class="detail-info-value" v-else-if="val.rich">
                <div class="rich-preview" v-html="detail[val.key]" v-if="detail[val.key]"></div>
                <span v-else>无</span>
              </div>
              <div class="detail-info-value" v-else-if="val.slot">
                <vnodes :vnodes="val.slot(detail)"/>
              </div>
              <div class="detail-info-value" v-else>{{isEmpty(detail[val.key]) ? '无' : detail[val.key]}}</div>
            </div>
          </li>
        </ul>
        <component :is="conf.component" :info="detail" v-if="conf.component"></component>
      </div>
      <a-space class="detail-footer" v-if="canUpdate || canDelete">
        <a-button type="primary" @click="editDetail" v-if="canUpdate">编辑</a-button>
        <a-button type="danger" @click="deleteDetail" v-if="canDelete">删除</a-button>
<!--        <a-button @click="auditDetail" v-action:audit v-if="detail.status == 0">审核</a-button>-->
      </a-space>
    </div>
    <a-modal :maskClosable="false" v-model="visible" title="审核" ok-text="确认" cancel-text="取消" @ok="confirmAudit">
      <a-form-model
          class="item-form"
          ref="editForm"
          layout="vertical"
          labelAlign="left"
          :model="single">
        <a-form-model-item label="审核结果">
          <a-radio-group v-model="single.status" :options="auditStatusList"></a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import entityConfig from "../common/constant/entity";
import {clone, getUrlFileName, downLoadFile, omit, isEmpty} from "../common/js/tool";
import {auditStatusList} from "../common/constant/status";
import {getTempMenu} from "../common/constant/template";
import HRProgramOther from "./hr/HRProgramOther";
import HolidayOther from "./oa/HolidayOther";
import NoticeOther from "./oa/NoticeOther";
import VolunteerList from './hr/volunteer/components/VolunteerList';
import BuddhaTkOther from "./buddha/BuddhaTkOther";
import GuideOther from "./rec/GuideOther";

export default {
  name: "EntityDetail",
  components: {
    HRProgramOther,
    HolidayOther,
    NoticeOther,
    VolunteerList,
    BuddhaTkOther,
    GuideOther,
  },
  data() {
    return {
      edit: false,
      detail: null,
      single: {},
      form: {},
      visible: false,
      auditStatusList,
      getUrlFileName,
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    type() {
      return this.$route.params.type
    },
    conf() {
      let type = this.type;
      return entityConfig[type];
    },
    //是新增还是详情
    isAdd() {
      return this.$route.name == 'AddEntity'
    },
    renderItems() {
      let conf = this.conf;
      if(conf) {
        let items = conf.renderItems(this, this.detail);
        return items
      } else {
        return [];
      }
    },
    detailForm() {
      return this.$refs.detailForm;
    },
    action() {
      // 遍历匹配的路由找到公共实体路由的action配置
      let matched = this.$route.matched;
      let route = matched.find(r => r.components.default.name == 'Entity');
      let res = null;
      if(route && route.meta && route.meta.action) {
        let type = this.type;
        res = route.meta.action[type] || null;
      }
      return res;
    },
    temp() {
      // 遍历匹配的路由找到公共实体路由的temp配置
      let matched = this.$route.matched;
      let route = matched.find(r => r.components.default.name == 'Entity');
      let res = null;
      if(route && route.meta && route.meta.tempMap) {
        let type = this.type;
        res = route.meta.tempMap[type] || null;
      }
      return res;
    },
    canUpdate() {
      return this.action && this.action.indexOf('update') >= 0 && !this.conf?.hideEdit;
    },
    canDelete() {
      return this.action && this.action.indexOf('delete') >= 0 && !this.conf?.hideDelete;
    }
  },
  watch: {
    conf() {
      this.detail = null;
      this.init();
    },
    action() {
      this.checkPermission();
    },
    temp() {
      // 缓存路由需判断当前路由是否显示
      if(this.$route.name == 'List') {
        this.setTempMenu();
      }
    }
  },
  created() {
    this.checkPermission(true);
    this.setTempMenu();
  },
  methods: {
    isEmpty,
    setTempMenu() {
      let temp = this.temp;
      if(temp) {
        let current = this.$store.getters.temp;
        if(current && current.type == temp.type) {
          return;
        }
        this.$axios(`/admin/apply-temp?pageSize=1&filter[type]=${temp.type}`).then(res => {
          if(res.data.length > 0) {
            this.$store.commit("setSubMenus", getTempMenu(res.data[0]));
          }
        })
      }
    },
    checkPermission(init) {
      // 检查权限
      let action = this.action;
      let actionName = this.isAdd ? 'add' : (this.$route.query.edit ? 'update' : 'query');
      if(!action || action.indexOf(actionName) === -1 ) {
        this.$router.replace({name: 'Forbidden'});
      } else if(init) {
        this.init();
      }
    },
    init() {
      let conf = this.conf;
      if(this.isAdd) {
        this.form = conf && conf.init ? clone(conf.init) : {};
        document.title = `添加${conf.name}`;
        //添加实体通过query参数查看有无默认关联实体
        let query = this.$route.query;
        let keys = Object.keys(query);
        const form = this.$store.getters.form;
        if(keys.length > 0) {
          // 如果有关联实体 则先从store中取出关联的实体对象 如果不存在（刷新了页面）则从服务端获取
          keys.forEach(key => {
            if(form && form[key]) {
              this.$set(this.form, key, form[key]);
            } else {
              this.$axios(`/${key}/${query[key]}`).then(res => {
                this.$set(this.form, key, res);
              });
            }
          })
        } else {
          // 如果detail 有值则拷贝一份 自动填充表单
          const detail = this.$store.getters.detail;
          if(detail && detail.type == this.type) {
            this.form = omit(detail.obj, ["id"]);
          }
        }
      } else {
        document.title = `${conf.name}详情`
        this.getEntity();
      }
    },
    confirmAudit() {
      let single = this.single;
      if(single.status) {
        this.updateDetail({status: single.status, id: this.detail.id});
        this.visible = false;
      } else {
        this.$message.warning("请选择审核结果")
      }
    },
    previewMedia(media, item) {
      let value = this.detail[item.key];
      let temp = value.filter(v => v.type == 'image').map(img => {
        return {
          url: img.url,
          title: item.label
        }
      });
      this.$store.dispatch("showImgList", temp, media.url);
    },
    previewImg(img, item) {
      let value = this.detail[item.key];
      if(typeof value === 'string') {
        value = value.split(",").map(v => {
          return {
            url: v,
            title: item.label
          }
        });
      }
      this.$store.dispatch("showImgList", value, img);
    },
    setEditStatus() {
      if(this.$route.query.edit) {
        this.editDetail()
      } else {
        this.cancelEdit();
      }
    },
    auditDetail() {
      let action = this.action;
      if(action.indexOf("audit") === -1) {
        this.$message.warning("您没有权限");
        return;
      }
      this.visible = true;
      this.single = {status: 1};
    },
    deleteDetail() {
      const detail = this.detail;
      const conf = this.conf;
      if(typeof conf.customDelete === 'function') {
        conf.customDelete.call(this, detail, -1, () => {
          this.$router.go(-1);
        })
      } else {
        this.$confirm({
          title: '提示',
          content: `确定删除此${conf.name}吗？`,
          onOk: () => {
            this.$axios({
              url: `${conf.url}/${detail.id}`,
              method: 'DELETE'
            }).then(() => {
              this.$message.success('已删除');
              this.$store.commit("changeUpdate", {type: this.type})
              this.$router.go(-1);
            })
          }
        });
      }
    },
    editDetail() {
      let action = this.action;
      if(action.indexOf("update") === -1) {
        this.$message.warning("您没有权限");
        return;
      }
      let conf = this.conf;
      if(conf && typeof conf.beforeEdit === 'function') {
        conf.beforeEdit.call(this, this.detail).then(this.showEdit);
      } else {
        this.showEdit();
      }
    },
    showEdit() {
      this.form = clone(this.detail);
      this.edit = true;
    },
    cancelEdit(){
      this.form = {};
      this.edit = false;
    },
    getEntity() {
      let detail = this.$store.getters.detail;
      let id = this.$route.params.id;
      if(detail && detail.type == this.type && detail.obj.id == id) {
        this.dealInfo(clone(detail.obj));
      } else {
        this.getDetail();
      }
    },
    getDetail() {
      let id = this.$route.params.id;
      if(!id) return;
      let conf = this.conf;
      let url = `${conf.url}/${id}${conf.expand ? conf.expand : ''}`;
      this.$axios(url).then(res => {
        this.dealInfo(res)
      });
    },
    dealInfo(info) {
      let conf = this.conf;
      if(conf && typeof conf.dealDetail === 'function') {
        conf.dealDetail.call(this, info).then(res => {
          this.detail = res || info;
          this.setEditStatus();
        })
      } else {
        this.detail = info;
        this.setEditStatus();
      }
    },
    confirm(form) {
      let conf = this.conf;
      if(conf && typeof conf.beforeUpdate === 'function') {
        conf.beforeUpdate(form).then(res => {
          this.updateDetail(res);
        })
      } else {
        this.updateDetail(form);
      }
    },
    updateDetail(form) {
      let url = this.conf.url;
      let method = 'POST';
      if(form.id) {
        url += '/' + form.id;
        method = 'PATCH';
        delete form.create_time;
        delete form.update_time;
      }
      this.$axios({
        url,
        method,
        data: form
      }).then(() => {
        this.$message.success("保存成功");
        this.$store.commit("changeUpdate", {type: this.type})
        this.getDetail();
        this.cancel();
      })
    },
    cancel() {
      this.cancelEdit();
      if(this.$route.query.edit || this.isAdd) {
        this.$router.back();
      }
    },
    downloadFile(f) {
      if(typeof f === "object") {
          downLoadFile(f.url, f.name);
      } else {
          downLoadFile(f, getUrlFileName(f));
      }
    },
  }
}
</script>

<style scoped lang="less">
.detail-box {
  position: relative;
  overflow: auto;
}
.detail-ctx,
.add-form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.detail-ctx {
  display: flex;
  flex-direction: column;
}
.detail-ctx-ti {
  flex-shrink: 0;
  padding: 10px 30px;
  border-bottom: var(--border);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.detail-ctx-box {
  flex: 1;
  overflow: auto;
  padding: 10px 30px;
}
.detail-info {
  margin-bottom: 0;
}
.detail-info-item {
  display: flex;
  padding: 6px 0;
  line-height: 1.4;
  font-size: 16px;
  &.vertical {
    flex-direction: column;
    .detail-value {
      margin: 10px 0 0;
    }
  }
  .rich-preview {
    width: 484px;
  }
}
.detail-info-label {
  min-width: 120px;
  color: @text-color-secondary;
}
.detail-info-value {
  flex: 1;
  margin-left: 20px;
  color: @text-color;
  text-align: justify;
}
.detail-footer {
  margin-top: 16px;
  padding: 10px 0;
  border-top: var(--border);
  justify-content: center;
}
.files-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: @primary-color;
}
.download-icon {
  margin-left: 10px;
}
</style>
