<template>
  <div class="buddha-tk-other">
    <div class="other-title">关联导览</div>
    <data-list
      ref="dataList"
      url="/admin/mini-guide"
      :query="`&filter[fid]=${info.id}`"
      entity-name="导览"
      :add-btn="false"
      hide-show-detail
      hide-edit
      hide-delete
      :height="500"
      :action="action"
      :columns="columns"
      v-if="info">
      <template v-slot:button>
        <entity-picker
          btn-text="添加"
          multiple
          url="/admin/mini-guide"
          :query="`&filter[fid]=0`"
          :columns="columns"
          @change="handleGuideChange"
          style="display:inline-block;margin-left: 8px">
          <template v-slot:query="{form}">
            <a-form-model-item label="">
              <a-input v-model="form.name" placeholder="输入导览名称查询"/>
            </a-form-model-item>
          </template>
          <a-button type="primary">添加</a-button>
        </entity-picker>
      </template>
      <template v-slot:query="{form}">
        <a-form-model-item label="">
          <a-input v-model="form.name" placeholder="输入导览名称查询"></a-input>
        </a-form-model-item>
      </template>
      <template v-slot:action="{item}">
        <a-popconfirm title="确定删除？" ok-text="确定" cancel-text="取消" @confirm="removeGuide(item)">
          <a class="txt-btn danger">删除</a>
        </a-popconfirm>
      </template>
    </data-list>
  </div>
</template>

<script>
    import {getTemple} from "../../common/js/storage";
    import entityPicker from "../../components/controls/entity-picker";
    import {guideColumns} from "../../common/constant/culture/guide";

    export default {
        name: "BuddhaTkOther",
        props: {
            info: Object
        },
        components: {
            entityPicker,
        },
        data() {
            return {
                columns: [...guideColumns],
                list: []
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            }
        },
        methods: {
            getTemple,
            handleGuideChange(val) {
                if(val) {
                    const ids = val.map(item => item.id).join(",");
                    this.$axios.post("/admin/mini-guide/batch-update", {
                        ids,
                        fid: this.info.id
                    }).then(() => this.updateList());
                }
            },
            updateList() {
                this.$refs.dataList.getList();
            },
            removeGuide(item) {
                const url = `/admin/mini-guide/${item.id}`;
                this.$axios({
                    url,
                    method: "PATCH",
                    data: {
                        fid: null,
                    }
                }).then(() => this.updateList());
            }
        }
    }
</script>

<style lang="less">
.buddha-tk-other {
  margin-top: 16px;
}
</style>
